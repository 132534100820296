import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

// Tientech
import VueSocketIO from 'vue-socket.io'
import SocketIO from 'socket.io-client'
import moment from 'moment'
import vuemoment from 'vue-moment'
import vuecookies from 'vue-cookies'
import { $themeConfig } from '@themeConfig'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueEllipseProgress from 'vue-ellipse-progress'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VueEllipseProgress)

// Composition API
Vue.use(VueCompositionAPI)

// Tientech
moment.locale('zh-tw')
Vue.use(vuemoment, { moment })
Vue.use(vuecookies)

library.add(fas)
library.add(far)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.$cookies.config('1h')
const socketio = SocketIO('/', { path: '/socketio_api/' })
Vue.use(
  new VueSocketIO({
    debug: false,
    connection: socketio,
    vuex: {
      store,
      actionPrefix: 'SOCKET_',
      mutationPrefix: 'SOCKET_',
    },
    options: { path: '/socketio_api/' },
  })
)
if (window.tientech === undefined) {
  window.tientech = {
    timeout: {},
    interval: {},
  }
}
if (window.tientech.socketio === undefined) {
  window.tientech.socketio = socketio
}
if (window.tientech.socketio.connected === false) {
  window.tientech.socketio.connect()
  window.tientech.socketio.on('connect', () => {
    clearInterval(window.tientech.interval.connectstatus)
  })
  window.tientech.socketio.on('disconnect', () => {
    if (window.tientech.interval.connectstatus === undefined) {
      window.tientech.interval.connectstatus = 0
    }
    clearInterval(window.tientech.interval.connectstatus)
    window.tientech.interval.connectstatus = setInterval(() => {
      window.tientech.socketio.connect()
    }, 100)
  })
}

if (window.tientech.i18n === undefined) {
  window.tientech.i18n = i18n
}
if (window.tientech.themeConfig === undefined) {
  window.tientech.themeConfig = $themeConfig
}

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard
require('@core/scss/vue/libs/vue-sweetalert.scss')
// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h =>
h(App),
}).$mount('#app')
