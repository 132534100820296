export const initialAbility = [
  {
    subject: 'Auth',
    action: 'read',
  },
]
export const accountAbility = {
  admin: [{
    subject: 'all',
    action: 'manage',
  }],
  dealer: [{
    subject: 'all',
    action: 'manage',
  }],
  general: [{
    subject: 'Auth',
    action: 'read',
  }, {
    subject: 'User',
    action: 'read',
  }, {
    subject: 'Subscription',
    action: 'read',
  },
  ],
  visitor: [
    {
      subject: 'Auth',
      action: 'read',
    },
  ],
}
export const _ = undefined
