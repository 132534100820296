import { Ability } from '@casl/ability'
import { initialAbility } from './config'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this

const getCookie = name => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) {
    const cookie = parts.pop().split(';').shift()
    let cookiestr = ''
    cookiestr = decodeURIComponent(cookie)
    cookiestr = decodeURIComponent(cookiestr)
    cookiestr = JSON.parse(cookiestr)
    return cookiestr
  }
  return null
}
const userData = getCookie('userData')
// const userData = JSON.parse(localStorage.getItem('userData'))
const existingAbility = userData ? userData.ability : null

export default new Ability(existingAbility || initialAbility)
