export default [
  {
    path: '/casestatus/:casepn',
    name: 'casestatus',
    component: () => import('@/views/dashboard/cases/CaseStatus.vue'),
    meta: {
      resource: 'User',
      action: 'read',
      title: 'casestatus',
      token: true,
    },
  },
  {
    path: '/casestatus',
    name: 'casestatus_error',
    redirect: { name: 'dashboard' },
    // component: () => import('@/views/dashboard/cases/CaseStatus.vue'),
    meta: {
      resource: 'User',
      action: 'read',
      title: 'casestatus',
      token: true,
    },
  },
  {
    path: '/casesetting/:casepn/:regionkey',
    name: 'devicecontrolsetting',
    component: () => import('@/views/dashboard/cases/CaseRegionSetting.vue'),
    meta: {
      resource: 'User',
      action: 'read',
      title: 'devicecontrolsetting',
      token: true,
    },
  },
  {
    path: '/casesetting/:casepn/',
    name: 'casesettingregionlist',
    component: () => import('@/views/dashboard/cases/CaseRegion.vue'),
    meta: {
      resource: 'User',
      action: 'read',
      title: 'devicecontrolsetting',
      token: true,
    },
  },
  {
    path: '/casesetting/',
    name: 'casesetting',
    component: () => import('@/views/dashboard/cases/CaseRegionHomePage.vue'),
    meta: {
      resource: 'User',
      action: 'read',
      title: 'casesetting',
      token: true,
    },
  },
  {
    path: '/casesetting',
    redirect: { name: 'casesetting' },
    component: () => import('@/views/dashboard/cases/CaseRegionHomePage.vue'),
    meta: {
      resource: 'User',
      action: 'read',
      title: 'casesetting',
      token: true,
    },
  },
  {
    path: '/casesetting*',
    name: 'casesettings',
    redirect: { name: 'casesetting' },
    meta: {
      resource: 'User',
      action: 'read',
      title: 'casesetting',
      token: true,
    },
  },
]
