import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import {
  isUserLoggedIn,
  getUserData,
  verifyUserToken,
  cleanallcookie,
  getHomeRouteForLoggedInUser,
} from '@/auth/utils'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import cases from './routes/cases'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import others from './routes/others'
// import { tientech } from '../../public/serverConfig'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: process.env.IS_ELECTRON ? 'hash' : 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...apps,
    ...cases,
    ...dashboard,
    ...pages,
    ...uiElements,
    ...others,
    { path: '/', redirect: { name: 'dashboard' } },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  // Title
  if (to.meta) {
    if (to.meta.title) {
      if (window.tientech.i18n) {
        let hostname = document.location.hostname
        if (window.tientech.themeConfig.webConfigs[hostname] === undefined) {
          hostname = '127.0.0.1'
        }
        const appName = window.tientech.themeConfig.webConfigs[hostname].appName
          ? window.tientech.themeConfig.webConfigs[hostname].appName
          : window.tientech.themeConfig.app.appName
        if (
          window.tientech.i18n.messages[window.tientech.i18n.locale] !==
            undefined &&
          window.tientech.i18n.messages[window.tientech.i18n.locale].pages[
            to.meta.title
          ] !== undefined &&
          window.tientech.i18n.messages[window.tientech.i18n.locale].pages[
            to.meta.title
          ].pagetitle !== undefined
        ) {
          window.document.title = `${
            window.tientech.i18n.messages[window.tientech.i18n.locale].pages[
              to.meta.title
            ].pagetitle
          } - ${appName}`
        } else {
          window.document.title = `${to.meta.title} - ${appName}`
        }
      }
    }
  }
  const isLoggedIn = isUserLoggedIn()
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'Login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }
  const userData = getUserData()

  if (userData) {
    if (!userData.enable) {
      return next({ name: 'misc-not-authorized' })
    }
  }

  if (to.meta.token !== undefined) {
    const isToken = verifyUserToken()
    if (isToken === undefined || isToken === null || isToken === '') {
      cleanallcookie()
      return next({ name: 'misc-not-authorized' })
    }
  }
  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
