export default [
  {
    path: '/apps/calendar',
    name: 'apps-calendar',
    component: () =>
import('@/views/apps/calendar/Calendar.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },

  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/users/view/:id',
    name: 'apps-users-view',
    component: () =>
import('@/views/apps/user/users-view/UsersView.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/apps/users/edit/:id',
    name: 'apps-users-edit',
    component: () =>
import('@/views/apps/user/users-edit/UsersEdit.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },

  // Invoice
  {
    path: '/apps/invoice/list',
    name: 'apps-invoice-list',
    component: () =>
import('@/views/apps/invoice/invoice-list/InvoiceList.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/apps/invoice/preview/:id',
    name: 'apps-invoice-preview',
    component: () =>
import('@/views/apps/invoice/invoice-preview/InvoicePreview.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/apps/invoice/add/',
    name: 'apps-invoice-add',
    component: () =>
import('@/views/apps/invoice/invoice-add/InvoiceAdd.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/apps/invoice/edit/:id',
    name: 'apps-invoice-edit',
    component: () =>
import('@/views/apps/invoice/invoice-edit/InvoiceEdit.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
]
