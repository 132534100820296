export default [
  {
    path: '/dashboard/',
    name: 'dashboard',
    component: () => import('@/views/dashboard/cases/DashboardHomePage.vue'),
    meta: {
      resource: 'User',
      action: 'read',
      title: 'dashboard',
      token: true,
    },
  },
  {
    path: '/dashboard*',
    name: 'dashboards',
    redirect: { name: 'dashboard' },
    meta: {
      resource: 'User',
      action: 'read',
      title: 'dashboard',
      token: true,
    },
  },
]
