import useJwt from '@/auth/jwt/useJwt'
import axios from '@axios'
/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
const getCookie = name => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) {
    const cookie = parts.pop().split(';').shift()
    let cookiestr = ''
    cookiestr = decodeURIComponent(cookie)
    cookiestr = decodeURIComponent(cookiestr)
    try {
      cookiestr = JSON.parse(cookiestr)
    } catch (error) {
      // error
    }
    return cookiestr
  }
  return null
}
const eraseCookie = name => {
  document.cookie = `${name} =; Max-Age=0`
}
const eraseAllwebitedata = () => {
  eraseCookie('userData')
  localStorage.removeItem('accessToken')
  localStorage.removeItem('refreshToken')
  localStorage.removeItem('userToken')
  localStorage.removeItem('userData')
  if (window.tientech.socketio !== undefined) {
    window.tientech.socketio.disconnect()
    setTimeout(() => {
      window.tientech.socketio.connect()
    }, 200)
  }
}

export const isUserLoggedIn = () => {
  try {
    const userData = getCookie('userData')
    axios.get('/updatetoken')
    if (!userData) {
      eraseAllwebitedata()
      return false
    }
    return true
  } catch (error) {
    console.log(error)
    return false
  }
}

export const getUserData = () => {
  try {
    const userData = getCookie('userData')
    axios.get('/updatetoken')
    if (!userData) {
      eraseAllwebitedata()
      return null
    }
    return userData
  } catch (error) {
    return null
  }
}
export const verifyUserToken = async () => {
  try {
    const userToken = await axios.get('/verifytoken')
    if (userToken !== undefined) {
      if (userToken.data !== undefined) {
        if (userToken.data.type === 'success') {
          return true
        }
      }
    }
    return null
  } catch (error) {
    return null
  }
}
export const cleanallcookie = () => {
  eraseAllwebitedata()
}

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return { name: 'access-control' }
  return { name: 'Login' }
}
